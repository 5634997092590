import React, { useEffect, useState } from 'react'
import {
  Seo,
  ProductCard,
  ShoppingWithBanner,
  Container,
  HeroSwiper,
  QHomeProductCard,
} from '../../components'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { useStoreContext } from '../../context/StoreContext'
import { useCartContext } from '../../context/CartContext'
// Styles
import { ProductsGrid, QHomeBanner } from '../../styles/LandingPage.styles'
// Services & Utils
import useWindowSize from '../../hooks/useWindowSize'
import { Magento } from '../../services/magento'
import { filterProducts } from '../../utils/productHelpers'

const QHomeType = ({ typeData }) => {
  const [singleProducts, setSingleProducts] = useState(null)

  const { isPcOfferInCart, isAmbOfferInCart, isAmbPremiumOfferInCart } =
    useCartContext()

  const { qUser } = useAuthContext()

  const {
    prismicData: { prismicQHome },
  } = usePrismic()

  useEffect(() => {
    ;(async () =>
      await Magento.Store.getSingleCategoryData({
        url_key: 'q-home-single-products',
      }).then(({ categories }) => {
        let category = categories.items[0]

        const categoryProducts = filterProducts(
          category.products.items,
          qUser,
          isPcOfferInCart,
          isAmbOfferInCart,
          isAmbPremiumOfferInCart
        )
        category.products = categoryProducts
        setSingleProducts(category)
      }))()
  }, [])

  const { width } = useWindowSize()

  const videoHeight = width > 900 ? 394 : width / 1.75

  return (
    <>
      <Seo title={typeData?.name ?? ''} />
      <ShoppingWithBanner />
      <Container direction="column" justify="flex-start" padding=".5rem 0px">
        <QHomeBanner src={prismicQHome?.q_home_banner?.url ?? null} />
        <Container
          direction="column"
          justify="flex-start"
          padding="2em"
          maxWidth="700px"
        >
          <span style={{ textAlign: 'center' }}>
            {prismicQHome?.tagline[0].text}
          </span>
          <span
            style={{
              textAlign: 'center',
              marginTop: '.5rem',
              marginBottom: '.5rem',
            }}
          >
            {prismicQHome?.tagline[1].text}
          </span>
        </Container>
        <object
          width="100%"
          height={videoHeight}
          data={prismicQHome?.video_url}
        ></object>
      </Container>
      <Container>
        <ProductsGrid>
          {typeData?.products?.map(product => (
            <ProductCard key={product.uid} {...product} />
          ))}
        </ProductsGrid>
      </Container>
      <Container padding="0">
        <HeroSwiper
          data-qa="qHomeHeroBanner"
          slide_images={prismicQHome?.q_home_slide_images}
        />
      </Container>
      <Container direction="column" padding="0">
        {singleProducts?.products?.map((product, index) => (
          <QHomeProductCard
            key={product.uid}
            product={product}
            direction={index % 2 === 0 ? 'row' : 'row-reverse'}
          />
        ))}
        <Container padding="2rem">
          <span style={{ fontWeight: 700 }}>
            {prismicQHome?.go_big_or_home[0].text}
          </span>
        </Container>
      </Container>
    </>
  )
}

export default QHomeType
